import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { H2, H4, Paragraph, TextContainer } from '../components/shared/typography'
import { ArrowLink, ExternalArrow } from '../components/shared/link'
import { Section } from '../components/shared/section'
import richTextParserNews from '../richTextParserNews'
import ArrowRight from '../components/svg/arrowRight'
import { Link } from 'gatsby'
/* eslint-disable max-len */

const Template = props => {
  const { pageContext } = props
  return (
    <Layout>
      <SEO title={`${ pageContext.title } - Job Posting`} />
      <div className="px-site mt-40 mb-12">
        <H4 className="md:pt-20 pt-10">
          <ArrowLink to="/careers">Back to Careers</ArrowLink>
        </H4>
        <h1 className="h2 pb-12 border-b border-solid">{pageContext.title}</h1>
        {pageContext.link && (
          <p>
            <a href={pageContext.link} className="group inline-flex gap-2 py-8" target="_blank">
              <span className='shrink-0 group-hover:text-purple group-focus:text-purple transition-colors duration-500'>Apply Now</span>
              <ArrowRight color='black' className="group-hover:translate-x-4 group-focus:translate-x-4 w-12 transition-transform duration-500" />
            </a>
          </p>
        )}
      </div>
      <article className="px-site">
        <div className="prose-p:font-light prose-p:max-w-prose prose-a:underline-offset-1 prose-a:decoration-purple prose-a:decoration-2 prose-a:underline prose-a:align-baseline prose-ul:list-disc prose-ol:list-decimal prose-h2:my-8 prose-headings:max-w-screen-xl prose-lg" >{richTextParserNews(pageContext.description)}</div>
      </article>
    </Layout>
  )
}

export default Template
